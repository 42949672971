$background-color: #191B1D;
$text-color: #FFFFFF;
$primary-color: #FFFFFF;

body {
  background-color: $background-color;
  color: $text-color;
  font-family: 'Arial', sans-serif;
}

a {
  color: $primary-color;
  text-decoration: none;
}
