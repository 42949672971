.project-modal {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1f1f1f;
  padding: 20px;
  border-radius: 8px;
  width: 900px;
  max-height: 90vh;
  overflow-y: auto;
  color: white;
}

.modal-content {
  margin-top: 20px;
}

.modal-button {
  margin-right: 10px;
}

.modal-footer {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

.stepper {
  background-color: transparent;
  padding: 10px 0;
}
