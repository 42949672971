.about {
  padding: 3rem 0;

  .tech-stack {
    margin-bottom: 2rem;

    .tech-title {
      color: #EF002F;
      margin-bottom: 1rem;
    }
    .tech-title-alt {
      color: #c0c0c0;
      margin-bottom: 20px !important;
    }

    .tech-button {
      width: 100%;
      border-radius: 8px;
      text-align: center;
      padding: 10px;
      border: 1px solid rgba(255, 255, 255, 0.05);  /* Matches the border from FeaturesSection */
      transition: all 0.3s ease;

      &:hover {
        background-color: #EF002F;
        color: #fff;
      }
    }
  }

  .right-column {
    color: #fff;

    .about-title {
      margin-bottom: 1.5rem;
    }

    .highlighted-text {
      font-weight: bold;
      color: #EF002F;
    }

    .conclusion {
      margin-top: 1.5rem;
      font-weight: bold;
    }

    p {
      color: #aaa;
    }
  }
}
