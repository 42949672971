.projects-section {
  width: 100%;
  background-color: #121212;
  padding: 50px 0;
  .subtitle {
    text-align: center;
    margin-bottom: 40px;
  }
  .project-item {
    background-color: #1A1B1D;
    padding: 20px;
    text-align: center;
    cursor: pointer;

    .portfolio-item {
      margin-top: 10px;

      img {
        width: 100%;
        height: auto;
      }
    }

    .learn-more {
      margin-top: 10px;
      color: #EF002F;
      text-decoration: none;
      font-weight: bold;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.modal-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1f1f1f;
  padding: 20px;
  color: white;
  border-radius: 8px;
  //width: 80%;
  //max-width: 600px;

  h5 {
    color: #EF002F;
  }
}
.projects-modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #1f1f1f;
  padding: 20px;
  border-radius: 8px;
  width: 600px;
  color: white;
  text-align: center;
}
