.budget-calculator-container {
  display: flex;
  justify-content: center;
  align-items: center;
  /* Removed height: 100vh; */
  padding: 2rem;  /* Optional: You can add padding if needed for spacing */
}

.budget-calculator-box {
  background-color: #121212;
  padding: 2rem;
  border-radius: 8px;
  width: 800px;
  max-width: 100%;
}

.service-select {
  margin-bottom: 1.5rem;
}

.price-slider {
  width: 80%;
  margin-right: 1rem;
}

.price-input {
  width: 120px;
}

.included-features {
  margin-top: 1.5rem;
  .MuiFormControlLabel-root {
    display: block;
  }
}
