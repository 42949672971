.portfolio-section {
  background-color: #121212;
  padding: 50px 0; // Adds 50px padding to top and bottom of the entire section
  width: 100%; // Ensures the background is full-width
  .subtitle {
    text-align: center;
    margin-bottom: 40px;
  }
  .portfolio {
    padding: 0 1rem; // Optional: Adjust horizontal padding if needed

    .portfolio-item {
      background-color: #1f1f1f;
      text-align: center;
      margin: 25px; // Adds 25px margin around each portfolio item

      a {
        display: block;

        img {
          width: 100%;
          height: auto; // Ensures image scales properly
        }
      }
    }
  }
}
