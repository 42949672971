.features-section {
  padding: 3rem 1rem;

  .sec-head {
    margin-bottom: 2rem;

    .header-box {
      text-align: center;
      h6 {
        color: #EF002F; /* Red color for the subtitle */
        animation-delay: 0.5s;
      }

      h3 {
        font-size: 2rem;
        animation-delay: 0.7s;
        color: #fff; /* Assuming white text */
      }
    }
  }

  .item-box {
    background-color: #191B1D;
    padding: 80px 30px;  /* Adjust padding similar to .services .item-box */
    border: 1px solid rgba(255, 255, 255, 0.05);  /* Similar border style */
    border-radius: 8px;
    text-align: center;
    transition: transform 0.3s ease-in-out;

    .icon {
      font-size: 3rem;
      margin-bottom: 1rem;
      color: #EF002F; /* Red color for the icons */
    }

    h6 {
      font-size: 1.5rem;
      margin-bottom: 0.5rem;
      color: #fff;
    }

    p {
      color: #aaa; /* Light grey for the text */
    }

    &:hover {
      transform: translateY(-10px);
    }
  }
}
