// _ButtonStyles.scss
.button-red-outline {
  color: transparent;
  border: 1px solid #EF002F !important;
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: bold;
  transition: background-color 0.3s ease;

  &:hover {
    color: #ffffff !important;
    border: 1px solid transparent !important;
  }
}
