@import '../../styles/variables';
.contact {
  padding: 3rem 1rem;

  .contact-title {
    text-align: center;
    margin-bottom: 2rem;
  }

  .contact-form {
    max-width: 600px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

  .contact-submit {
    background-color: $primary-color;
    color: $background-color;
    padding: 1rem;
    font-size: 1rem;
    &:hover {
      background-color: darken($primary-color, 10%);
    }
  }
}
