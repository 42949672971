@import '../../styles/_ButtonStyles.scss';
.call-to-action {
  padding: 3rem 0;

  .cta-content {
    .animated-text {
      visibility: visible;
      animation-name: fadeInUp;
      animation-duration: 0.8s;
      animation-fill-mode: both;
    }

    h2{
      text-transform: uppercase;
    }
    h6 {
      font-size: 1.5rem;
      color: #999;
      margin-bottom: 1rem;
      text-transform: uppercase;
    }

    h2 {
      font-size: 3rem;
      font-weight: bold;
      color: #fff;
      line-height: 1.2;
      .back-color {
        background-color: #e63946;
        padding: 0 5px;
        text-transform: uppercase; // Ensures NEXT is uppercase
      }
    }
  }

  .cta-button-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .cta-button {
      border-color: #fff;
      color: #fff;
      padding: 0.75rem 2rem;
      font-weight: bold;

      &:hover {
        background-color: #e63946;
        border-color: #e63946;
      }
    }
  }

  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translate3d(0, 40px, 0);
    }
    to {
      opacity: 1;
      transform: translate3d(0, 0, 0);
    }
  }
}
