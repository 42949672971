.hero {
  padding: 100px 0;
  text-align: center;
  background-color: #121212;

  .evol-labs-box {
    background-color: #1f1f1f; /* Dark background for "Evolve Labs" */
    padding: 30px 60px; /* 50% bigger */
    display: inline-block;
    border-radius: 8px;
    margin-bottom: 20px;
  }

  .hero-title {
    font-size: 90px; /* 50% bigger */
    font-weight: bold;

    .red-text {
      color: #EF002F; /* Red color for "EVOL" */
    }

    .light-grey-text {
      color: #b0b0b0; /* Light grey color for "VE LABS" */
    }
  }

  .innovation-box {
    padding: 10px 30px;
    display: inline-block;
    border-radius: 8px;
    border: 1px solid #EF002F; /* Red outline similar to button style */
  }

  .hero-subtitle {
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    letter-spacing: 3px;
  }

  /* Responsive adjustments for different screen sizes */
  @media (max-width: 1200px) {
    .hero-title {
      font-size: 70px; /* Reduce size for large tablets */
    }
  }

  @media (max-width: 900px) {
    .evol-labs-box {
      padding: 20px 40px; /* Adjust padding on smaller screens */
    }

    .hero-title {
      font-size: 60px; /* Smaller font size for tablets */
    }

    .innovation-box {
      padding: 8px 20px; /* Reduce padding for subtitle box */
    }

    .hero-subtitle {
      font-size: 0.9rem; /* Adjust font size for tablets */
    }
  }

  @media (max-width: 600px) {
    padding: 50px 0; /* Less padding for smaller devices */

    .evol-labs-box {
      padding: 15px 30px; /* Smaller padding for mobile */
    }

    .hero-title {
      font-size: 45px; /* Smaller font size for mobile */
    }

    .innovation-box {
      padding: 6px 15px; /* Further reduce padding for mobile */
    }

    .hero-subtitle {
      font-size: 0.8rem; /* Adjust subtitle font size for mobile */
    }
  }
}
