@import '../../styles/variables';
.footer {
  background-color: #121212;
  padding: 20px 0;
  color: #fff;

  .company-info {
    font-size: 1rem;
  }

  .social-icons {
    display: flex;
    gap: 10px;

    .icon-button {
      color: #fff;

      &:hover {
        color: #EF002F; /* Red hover effect */
      }
    }
  }
}
