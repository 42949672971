@import '../../styles/_ButtonStyles.scss';
.header {
  background-color: #121212 !important; /* Dark background for the navbar */

  .logo {
    flex-grow: 1;

    .red-text {
      color: #EF002F; /* Red color for the "E" */
      font-weight: bold;
      font-size: 3rem; /* Make the "E" very bold and large */
    }
  }

  .nav-links {
    display: flex;
    gap: 20px;

    button {
      color: #fff;

      &:hover {
        color: #EF002F;
      }
    }
  }
}
